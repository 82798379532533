<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目名称">
              <a-input class="form-control" v-decorator="['prjName']" placeholder="请输入项目名称" allowClear></a-input>
            </a-form-item>
            <a-form-item label="项目编码">
              <a-input class="form-control" v-decorator="['prjCode']" placeholder="请输入项目编码" allowClear></a-input>
            </a-form-item>
            <a-form-item label="日期">
              <a-month-picker v-decorator="['dateTime', { initialValue: moment() }]" />
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-upload name="file" @change="handleUploadChange" :beforeUpload="beforeUpload" :customRequest="doUpload">
            <template>
              <a-button>
                <a-icon type="upload" />
                批量导入
              </a-button>
              <a @click.stop="download">
                <a-icon type="download"></a-icon>
                模板下载
              </a>
            </template>
          </a-upload>
        </div>

        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import fileDownload from 'js-file-download'
import axios from 'axios'
import moment from 'moment'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '项目名称',
        width: '20%',
        align: 'center',
        dataIndex: 'prjName',
      },
      {
        title: '项目编码',
        width: '15%',
        align: 'center',
        dataIndex: 'prjCode',
      },

      {
        title: '平均人数',
        width: '15%',
        align: 'center',
        dataIndex: 'num',
      },
      {
        title: '时间',
        width: '15%',
        align: 'center',
        dataIndex: 'dateTime',
      },
      {
        title: '操作',
        width: '15%',
        align: 'center',
        customRender: (text, row, index) => {
          return (
            row.id && (
              <a
                onClick={() => {
                  this.removeRow(row)
                }}
              >
                删除
              </a>
            )
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {
        status: [],
      }
    },
  },
  methods: {
    moment,
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.formatParams(this.getPageParams(_pageNo, _pageSize), (key, value) => {
          if (key === 'dateTime') {
            return { key, value: value ? value.format('YYYY-MM') : '' }
          }
          return { key, value }
        })
        const res = await this.$axios.post('/report/peoplenum/listPage', {
          ...params,
          type: 1,
        })
        const list = res.data.data
        const { pageSize, pageNo, totalCount } = res.data
        list.unshift(res.data.dataHZ[0])
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async removeRow(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认删除?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/report/peoplenum/delete', {
        id: row.id,
      })
      this.$message.success('操作成功')
      this.doQuery()
    },
    async download() {
      const sourceSrc = 'https://van-tai-statics.oss-cn-hangzhou.aliyuncs.com/worker/项目人数.xlsx'
      const serverRes = await axios.get(sourceSrc, { responseType: 'blob' })
      fileDownload(serverRes.data, '项目人数.xlsx')
    },
    beforeUpload(file) {
      const maxFileSize = 10
      const isLtSize = file.size / 1024 / 1024 < maxFileSize
      if (!isLtSize) {
        this.$message.error(`上传文件不能大于${maxFileSize}M!`)
        return false
      }
    },
    async handleUploadChange({ file, fileList }) {
      if (file.status === 'done') {
        this.$message.success(`${file.name} 上传成功`)
        this.doQuery(1)
      } else if (file.status === 'error') {
        console.log(file)
      }
    },
    doUpload(ev) {
      const { file, onError, onProgress, onSuccess } = ev
      this.$axios
        .post(
          '/report/peoplenum/import',
          { file: file },
          {
            postDataType: 'formData',
            onUploadProgress: function (progressEvent) {
              onProgress(
                {
                  percent: progressEvent.lengthComputable ? progressEvent.loaded / progressEvent.total : 1,
                },
                file
              )
            },
          }
        )
        .then(() => {
          // 第一个参数会回写到 file的response
          onSuccess({}, file)
        })
        .catch((e) => {
          // console.log(e)
          // 第二个参数会回写到response,response 为字符串时，会直接展示为错误提示
          onError(e, `上传失败：${e.message}`)
        })
    },
  },
  async mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
